
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';

export default defineComponent({
  name: 'pending-activities-report',
  mixins: [apiEndpoint],
  components: {
    Datatable,
  },
  data() {
    return {
      trainee: {
        tranche_id: '',
      },
      totalPendingEnrollment: 0,
      totalPendingAttendace: 0,
      totalPendingAssessment: 0,
      totalPendingCertification: 0,
      totalPendingEmployment: 0,
      associationDataCount: [],
      api_url: '',
      load: false,
      loading: false,
      courseInfoData: false,
      showDashboardData: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getAssociationDataCount();
  },
  methods: {
    async getAssociationDataCount() {
      this.load = true;
      await ApiService.get('report/pending-activities')
        .then((response) => {
          this.associationDataCount = response.data.data;
          this.totalPendingEnrollment =
            response.data.data[0].total_pending_enrollment;
          this.totalPendingAttendace =
            response.data.data[0].total_pending_attendace;
          this.totalPendingAssessment =
            response.data.data[0].total_pending_assessment;
          this.totalPendingCertification =
            response.data.data[0].total_pending_certification;
          this.totalPendingEmployment =
            response.data.data[0].total_pending_employment;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {},
});
